'use client';

import { signIn, signOut, useSession } from "next-auth/react";

export function HeaderButton() {
  const session = useSession();

  return session.data?.user ? (
    <>
      <span>{session.data.user.name || session.data.user.email}</span>
      <SignOutButton />
    </>
  ) : (
    <SignInButton />
  )
}

export function SignInButton() {
  return <button type="button" onClick={() => signIn()}>Sign in</button>
}

export function SignOutButton() {
  return <button type="button" onClick={() => signOut()}>Log out</button>
}
