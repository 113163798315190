import(/* webpackMode: "eager" */ "/Users/oscararmstrong/node/policy-tracker/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/oscararmstrong/node/policy-tracker/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/oscararmstrong/node/policy-tracker/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/oscararmstrong/node/policy-tracker/src/app/_components/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderButton"] */ "/Users/oscararmstrong/node/policy-tracker/src/app/_components/SignInButton.tsx");
;
import(/* webpackMode: "eager" */ "/Users/oscararmstrong/node/policy-tracker/src/app/globals.css");
